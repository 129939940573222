<!-- @Author: Yu_Bo -->
<template>
	<div class='text_page'>
    <!--  -->
    <div class="page_top">
      <div class="page_top_con">
        <div @click="closeBtn">
          <i class="el-icon-back"></i>
          <span>返回</span>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="page_con">
      <div v-html="htmlData"></div>
      <!-- <div class="con_title">{{title}}</div>
      <div class="con_time">本协议生效日期：2022年8月1日{{time}}</div>
      <div class="con_html">
        <div class="html_title">一、重要提示</div>
        <div class="html_text">
          <p>云川平台软件（以下简称为“本软件”）及相关服务，是指云川科技科技有限公司及关联方（以下简称为“我们”）拥有并运营的、标注名称为“云川平台”的应用程序向您提供的产品与服务。</p>
          <p>《用户协议》（以下简称“本协议”,或“用户协议”）是您与我们就您下载、安装、注册、登录、使用（以下统称“使用”）本软件，并获得本软件提供的相关服务所订立的协议。</p>
          <p>请您（以下亦称“用户”）在使用 本软件及相关服务，或者申请注册流程时仔细阅读本协议之全部条款，并确认您已完全理解本协议之规定，尤其是免除或者限制您责任的条款、对您权利进行限制的条款、争议解决条款、法律适用等条款。限制、免责条款或者其他涉及您重大权益的条款可能以加粗、加下划线等形式提示您重点注意。如您对本协议有任何疑问，请立即停止使用我们的本软件及相关服务，或者停止注册用户。</p>
          <p>如您因年龄、智力等因素而不具有完全民事行为能力，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用本软件及相关服务。</p>
          <p>如对本协议内容有任何疑问、意见或建议，与我们联系。</p>
        </div>
        <div class="html_title">二、协议的接受与变更</div>
      </div> -->
    </div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        title:'服务协议',
        time:'2022年8月1日',
        htmlData:'',
			}
		},
		computed: {

    },
		watch: {},
		created() {
      if(this.$route.params.txt){
        // 服务协议
        if(this.$route.params.txt=='service'){
          this.title='服务协议'
        }
        // 隐私政策
        if(this.$route.params.txt=='privacy'){
          this.title='隐私政策'
        }
        // 会员服务协议
        if(this.$route.params.txt=='membership'){
          this.title='会员服务协议'
        }
      }
      this.infoData(this.$route.params.txt)
    },
		mounted() {},
		methods: {
      infoData(txt){
        var params = {
          name:txt
        }
        this.$publicApi.registerAgreement(params).then(res => {
          if (res.code == 1000) {
            this.htmlData=res.result
          } else {
            this.$errMsg(res.message)
          }
        })
      },
      // 关闭浏览器窗口
      closeBtn(){
        window.close()
      }
    },
	}
</script>

<style lang='scss' scoped>
	.text_page{
    width: 100%;
    min-height: 100vh;
    background: #fff;
    .page_top{
      position: sticky;
      top: 0;
      width: 100%;
      height: 60px;
      background: #fff;
      border-bottom: 1px solid #EDEDED;
      .page_top_con{
        width: 900px;
        height: 60px;
        margin: auto;
        display: flex;
        align-items: center;
        div{
          display: flex;
          align-items: center;
          .el-icon-back{
            cursor: pointer;
            font-size: 18px;
            margin-top: 2px;
          }
          span{
            cursor: pointer;
            padding-left: 6px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
    /*  */
    .page_con{
      width: 900px;
      margin: auto;
      padding: 40px 0;
      .con_title{
        text-align: center;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .con_time{
        padding: 40px 0;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .con_html{
        width: 100%;
        .html_title{
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .html_text{
          width: 100%;
          padding-bottom: 40px;
          p{
            padding-top: 10px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
	}
</style>
